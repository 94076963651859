<template>
	<div>
		<a-spin v-show="!ordinary" :spinning="loading">
			<a-form ref="form" :model="formState" @finish="onSearch">
				<a-row>

				</a-row>
				<a-row>
					<a-col :span="16">
						<a-button type="primary" @click="onAdd" v-permission="['user_member_list_add']">新增等级</a-button>
					</a-col>
					<!-- <a-col :span="8">
						<a-button type="primary" html-type="submit">搜索</a-button>
						<a-button style="margin-left: 10px;">重置</a-button>
					</a-col> -->
				</a-row>
			</a-form>
			<div style="margin-top: 10px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
					:scroll="{ x: 950 }">
					<template #bodyCell="{ column, record, text }">
						<template v-if="column.key === 'head'">
							<a-image :width="50" :src="record.head" />
						</template>

						<template v-if="column.key === 'needGrowth'">
							<div v-if="record.name === '特色会员'">￥{{ record.price }}</div>
							<div v-else>{{ record.needGrowth }}</div>
						</template>
						<template v-if="column.key === 'isDisabled'">
							{{ record.isDisabled ? '已禁用' : '已启用' }}
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button>操作 <Icon icon="DownOutlined"></Icon></a-button>
								<template #overlay>
									<a-menu>
										<div>
											<a-menu-item @click="toSee(text)">
												查看
											</a-menu-item>
										</div>
										<div v-permission="['user_member_list_edit']">
											<a-menu-item @click="toEdit(record)">
												编辑
											</a-menu-item>
										</div>
										<div v-if="record.isDisabled && record.name != '特色会员'" v-permission="['user_member_list_disabled']">
											<a-menu-item @click="toDisabled(record)">
												启用
											</a-menu-item>
										</div>
										<div v-else-if="record.name != '特色会员'" v-permission="['user_member_list_disabled']">
											<a-menu-item @click="toDisabled(record)">
												禁用
											</a-menu-item>
										</div>
										<div v-permission="['user_member_list_delete']" v-if="record.isDisabled && record.name != '特色会员'">
											<a-menu-item @click="toDelete(record)">
												删除
											</a-menu-item>
										</div>
										<div v-permission="['user_member_list_generate']" v-if="record.name === '特色会员'">
											<a-menu-item @click="onCreate(record)">
												批量生成
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
		<div v-if="ordinary">
			<ordinary :id="id" :isEdit="isEdit" :isSee="isSee" @back="onBack"></ordinary>
		</div>
		<a-modal v-model:visible="showModal" title="批量生成" width="850px" @ok="onSubmit" @cancel="onCancel">
			<a-form ref="modalForm" :model="modelRef" :labelCol="{ span: 6 }" :wrapperCol="{ span: 17 }">
				<a-form-item label="生成数量" name="num" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-input-number v-model:value="modelRef.num" :min="0" :precision="0" placeholder="请输入"></a-input-number>
				</a-form-item>

				<a-form-item label="会员有效期" name="month" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-input-number v-model:value="modelRef.month" :min="1" :precision="0" placeholder="请输入"></a-input-number> 月
				</a-form-item>

				<a-form-item label="领取有效期类型" name="dayType" :rules="[{ required: true, message: '必选项不允许为空' }]">
					<a-radio-group v-model:value="modelRef.dayType">
						<a-radio :value="1">具体日期</a-radio>
						<a-radio :value="2">天数</a-radio>
					</a-radio-group>
				</a-form-item>
				<a-form-item v-if="modelRef.dayType === 1" label="具体日期" name="endDate" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-date-picker v-model:value="modelRef.endDate" showTime></a-date-picker>
				</a-form-item>
				<a-form-item v-if="modelRef.dayType === 2" label="天数" name="days" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input-number v-model:value="modelRef.days" :min="0" :precision="0" placeholder="请输入"></a-input-number> 天
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
	import ordinary from "@/views/user/member/ordinary";
	import {
		Icon
	} from '@/components/icon/icon.js';
	import {
		getMemberLevelList,
		getArchiveCode,
		deleteMemberLevel,
		disabledMemberLevel
	} from "@/service/modules/member";
	export default {
		components: {
			Icon,
			ordinary
		},
		data() {
			return {
				showModal: false,
				ordinary: false,
				isEdit: false,
				isSee: false,
				id: 0,
				loading: false,
				searchData: {},
				formState: {},
				columns: [{
					title: '会员等级',
					dataIndex: 'name',
				}, {
					title: '排序',
					dataIndex: 'level'
				}, {
					title: '所需成长值',
					dataIndex: 'needGrowth',
					key: 'needGrowth'
				}, {
					title: '状态',
					key: 'isDisabled'
				}, {
					title: '操作',
					key: 'action',
					width: 250
				}],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				modelRef: {
					dayType: 2
				}
			}
		},
		created() {
			this.getData();
		},
		methods: {
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getMemberLevelList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onSubmit() {
				this.$refs.modalForm.validate().then(async ()=> {
					let postData = this.$deepClone(this.modelRef);
					if (postData.dayType === 1) {
						postData.endDate = postData.endDate.unix();
						delete postData.days;
					} else {
						delete postData.endDate;
					}
					postData.memberLevelId = this.id;
					this.loading = true;
					let ret = await getArchiveCode(postData);
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('批量生成成功！');
						this.$refs.modalForm.resetFields();
						this.showModal = false;
						this.modelRef = {dayType: 2}
						this.getData();
					}
				})
			},
			toDisabled(item){
				this.$confirm({
				  title: '提示',
				  content: '确认' + (item.isDisabled ? '启用' : '禁用') + '吗？',
				  onOk: async () => {
				    this.loading = true;
				    try {
				      let ret = disabledMemberLevel({
				        id: item.id,
				        isDisabled: item.isDisabled ? 0 : 1
				      });
				      this.loading = false;
				      if(ret.code === 200) {
				        this.$message.success((item.isDisabled ? '启用' :'禁用') + '成功！');
				        this.getData();
				      }
				    } catch(e) {
				      this.loading = false;
				    }
				  }
				})
			},
			toDelete(item){
				this.$confirm({
				  title: '提示',
				  content: '确定删除吗？',
				  onOk: async ()=> {
				    this.loading = true;
				    try {
				      let ret = await deleteMemberLevel({
				        id: item.id
				      });
				      this.loading = false;
				      if (ret.code === 200) {
				        this.$message.success('操作成功');
				        this.getData();
				      }
				    } catch(e) {
				      this.loading = false;
				    }
				  }
				})
			},
			onCancel() {
				this.$refs.modalForm.resetFields();
				this.showModal = false;
				this.modelRef = {dayType: 2}
			},
			onBack(isRef) {
				this.ordinary = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			onAdd() {
				this.id = 0;
				this.isEdit = false;
				this.isSee = false;
				this.ordinary = true;
			},
			toEdit(item) {
				this.id = item.id;
				this.isEdit = true;
				this.isSee = false;
				this.ordinary = true;
			},
			toSee(item) {
				this.id = item.id;
				this.isEdit = false;
				this.isSee = true;
				this.ordinary = true;
			},
			onCreate(item) {
				this.id = item.id;
				this.modelRef = {dayType: 2};
				this.showModal = true;
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>